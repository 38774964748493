import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import ScheduleForm from '../forms/ScheduleForm';

class ModalTrigger extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {isModalOpen: false};

		this.handleClick = this.handleClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handleClick() {
		this.setState({
			isModalOpen: true
		});
	}

	handleClose() {
		this.setState({
			isModalOpen: false
		});
	}

	render() {
		return <Fragment>
			<a
				className={this.props.className}
				onClick={this.handleClick}>
				{this.props.children}
			</a>
			{
				this.state.isModalOpen ?
					<Modal>
						<ScheduleForm onClose={this.handleClose} />
					</Modal> :
					null
			}
		</Fragment>;
	}
}

ModalTrigger.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string
};

ModalTrigger.defaultProps = {
	className: ''
};

export default ModalTrigger;
