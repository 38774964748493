import React, {PureComponent, Fragment} from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import logo from '../assets/logos/libretto-logo-on-light.svg';
import menu from '../assets/icons/menu.svg';
import menuClose from '../assets/icons/menu-close.svg';
import heroImage from '../assets/images/jetty.jpg';
import ModalTrigger from './ModalTrigger';

const MOBILE_MENU_BP = 960;

const Left = styled.div`
	@media screen and (min-width: 960px) {
		grid-column: 1;
	}
`;

const Right = styled.div`
	@media screen and (min-width: 960px) {
		grid-column: 2;
		grid-row: 1 / 3;
		background-image: url(${heroImage});
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: cover;
	}
`;

const Logo = styled(Link)`
	text-decoration: none;
	outline: none;
	display: block;
	max-width: 175px;
	padding-top: 1.4em;
	padding-left: 1.3em;
	color: var(--logo-text-color);

	@media screen and (min-width: 960px) {
		padding-top: 1.7em;
		padding-left: 2.4em;
	}

	@media screen and (min-width: 1280px) {
		padding-top: 1.5em;
		padding-left: 5em;
	}
`;

const Hamburger = styled.a`
	text-decoration: none;
	outline: none;
	font-size: 2em;
	position: absolute;
	right: 0.75em;
	top: 1em;
	cursor: pointer;

	&.open {
		position: fixed;
	}

	@media screen and (max-width: 300px) {
		display: none;
	}
`;

const MenuImg = styled.img`
	width: 0.75em;
	height: 0.75em;
`;

const Img = styled.img`
	max-width: 8.9em;

	@media screen and (min-width: 960px) {
		max-width: 11em;
	}
`;

const Nav = styled.nav`
	@media screen and (max-width: 959px) {
		position: fixed;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		transition: width 0.1s ease-in;

		&.clopen,
		&.closed {
			a {
				opacity: 0;
				pointer-events: none;
			}
		}

		&.open {
			width: calc(100% - 4em);
			height: 100%;
			background: var(--navigation-mobile-bg-color);

			a {
				opacity: 1;
				transition: opacity 0.2s ease-in;
			}
		}
	}

	@media screen and (min-width: 960px) {
		background: linear-gradient(var(--navigation-gradient-start-color), var(--navigation-gradient-stop-color));
		padding-left: 4em;
		padding-top: 1.5em;
		padding-bottom: 1.5em;
	}

	@media screen and (min-width: 1280px) {
		padding-left: 4em;
	}
`;

const Item = styled(Link)`
	text-transform: uppercase;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	color: var(--navigation-text-color);
	padding-bottom: 0.25em;

	@media screen and (max-width: 959px) {
		display: block;
		margin: 2em;

		&:hover {
			text-decoration: underline;
		}
	}

	@media screen and (min-width: 960px) {
		margin-right: 2em;

		&:hover {
			border-bottom: 1px solid var(--navigation-hover-border-color);
		}
	}

	@media screen and (min-width: 1280px) {
		margin-right: 4em;
	}
`;

const ScheduleItem = styled(ModalTrigger)`
	text-transform: uppercase;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	color: var(--navigation-text-color);
	padding-bottom: 0.25em;

	@media screen and (max-width: 959px) {
		display: block;
		margin: 2em;

		&:hover {
			text-decoration: underline;
		}
	}

	@media screen and (min-width: 960px) {
		margin-right: 2em;

		&:hover {
			border-bottom: 1px solid var(--navigation-hover-border-color);
		}
	}

	@media screen and (min-width: 1280px) {
		margin-right: 4em;
	}
`;

const isBrowser = typeof window !== 'undefined';

const getPageWidth = () => !isBrowser ? 1000 :
	window.innerWidth || document.documentElement.clientWidth ||
	document.getElementsByTagName('body')[0].clientWidth;

class SiteNav extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isMenuOpen: false,
			isMobileMenuPossible: getPageWidth() < MOBILE_MENU_BP
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleItemClick = this.handleItemClick.bind(this);
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		if (isBrowser) {
			window.addEventListener('resize', this.handleResize);
		}
	}

	componentWillUnmount() {
		if (isBrowser) {
			window.removeEventListener('resize', this.handleResize);
		}
	}

	handleResize() {
		let isMobileMenuPossible = getPageWidth() < MOBILE_MENU_BP;
		if (isMobileMenuPossible !== this.state.isMobileMenuPossible) {
			this.setState({
				isMenuOpen: false,
				isMobileMenuPossible
			});
		}
	}

	handleClick() {
		this.setState({isMenuOpen: !this.state.isMenuOpen});
	}

	handleItemClick() {
		this.setState({isMenuOpen: false});
	}

	render() {
		return <Fragment>
			<Left>
				<Logo
					id="home"
					title="Home"
					to="/">
					<Img
						alt="Libretto Logo"
						src={logo} />
				</Logo>
			</Left>
			<Right className="nav-wrapper">
				{
					this.state.isMobileMenuPossible ?
						<Hamburger
							id="mobile-nav"
							className={this.state.isMenuOpen ? 'open' : 'closed'}
							onClick={this.handleClick}>
							{
								this.state.isMenuOpen ?
									<MenuImg src={menuClose} /> :
									<MenuImg src={menu} />
							}
						</Hamburger> :
						null
				}
				<Nav
					onClick={this.handleItemClick}
					className={this.state.isMenuOpen ?
						'open' :
						this.state.isMobileMenuPossible ? 'closed' : 'clopen'}
				>
					<Item
						to="/#our-approach-section"
						id="our-approach">
						Our Approach
					</Item>
					<Item
						to="/#our-solution-section"
						id="our-solution">
						Our Solution
					</Item>
					<ScheduleItem
						href="/#schedule-meeting-section"
						id="schedule-meeting">
						Schedule Meeting
					</ScheduleItem>
				</Nav>
			</Right>
		</Fragment>;
	}
}

export default SiteNav;
