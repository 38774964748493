import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
	:root {
		--main-bg-color: #fff;
		--secondary-bg-color: #f5f5f5;
		--tertiary-bg-color: #fafafa;
		--navigation-text-color: #fff;
		--navigation-alt-text-color: #000;
		--navigation-mobile-bg-color: #000;
		--navigation-hover-border-color: rgba(255, 255, 255, 0.6);
		--navigation-gradient-start-color: #003148;
		--navigation-gradient-stop-color: rgba(0, 49, 72, 0);
		--logo-text-color: #000;
		--header-text-color: #656565;
		--header-bg-color: #fff;
		--approach-hero-bg-color: #2c5f76;
		--approach-hero-title-text-color: #2c5f76;
		--approach-hero-text-color: #fff;
		--solution-hero-bg-color: #27415f;
		--solution-hero-title-text-color: #1e3e4f;
		--solution-hero-text-color: #fff;
		--section-info-title-text-color: #2c5f76;
		--section-info-text-color: #818181;
		--horizontal-section-title-text-color: #2c5f76;
		--horizontal-section-text-color: #818181;
		--vertical-section-title-text-color: #496371;
		--vertical-section-text-color: #496371;
		--section-bg-color: #fff;
		--section-shadow-color: rgba(204, 204, 204, 0.5);
		--schedule-bg-color: #00ada8;
		--schedule-text-color: #fff;
		--footer-bg-color: #3f3f3b;
		--footer-copyright-color: #6f6f6f;
		--footer-text-color: #c2c2c2;
		--footer-logo-text-color: #fff;
		--modal-border-color: #7798a7;
		--modal-title-text-color: #2c5f76;
		--modal-text-color: #818181;
		--modal-bg-color: #fff;
		--modal-overlay-bg-color: rgba(0, 0, 0, 0.75);
		--modal-input-text-color: #000;
		--modal-input-placeholder-color: #b3b3b3;
		--modal-input-border-color: #b3b3b3;
		--modal-button-outline-color: rgb(0, 173, 168, 0.3);
		--loader-ring-color: rgba(255, 255, 255, 0.3);
		--loader-bar-color: #fff;

		/** uncomment for spacing adjustments **/

		/*
		--main-bg-color: rgba(255,0,0, 0.1);
		--secondary-bg-color: rgba(255,255,0, 0.1);
		--tertiary-bg-color: rgba(0,255,0, 0.1);
		--footer-bg-color: rgba(0,0,255, 0.1);
		--modal-bg-color: rgba(255,0,255, 0.1);
		*/
	}

	html {
		scroll-behavior: smooth;
	}

	body {
		font-family: 'Lato', Arial, sans-serif;
		margin: 0;
		padding: 0;
		overflow-x: hidden;
		min-width: 320px;
		background: var(--main-bg-color);
		font-size: 14px;
		line-height: 1.4285em;
		color: rgba(0, 0, 0, 0.87);
		-webkit-font-smoothing: antialiased;

		&.modal {
			overflow: hidden;
		}
	}

	#modal-wrapper {
		background-color: var(--modal-overlay-bg-color);
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		bottom: 0;

		@media screen and (max-height: 720px) {
			overflow-y: scroll;
		}
	}

	h1 {
		font-size: 2em;
		margin: 0;
	}
`;
