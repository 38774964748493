import React, {PureComponent} from 'react';
import styled from 'styled-components';
import jwt from 'jsonwebtoken';
import PropTypes from 'prop-types';
import closeIcon from '../assets/icons/icon-close.svg';

const SEC = 'm7XNbQaDWc7pgXwi';

const Wrapper = styled.div`
	position: relative;
	border-top: 10px solid var(--modal-border-color);
	background: var(--modal-bg-color);
	margin: 0 auto 1em;
	padding: 2em;

	@media screen and (min-width: 375px) {
		margin: 14% auto 1em;
		padding: 3.4em 1.5em 0;
		max-width: 21em;
	}

	@media screen and (min-width: 1280px) {
		margin-top: 3%;
		max-width: 39em;
		padding: 3.8em 4em 0;
	}
`;

const IconElement = styled.span`
	width: 1.5em;
	height: 1.5em;
	display: block;
	cursor: pointer;
	position: absolute;
	top: 1.2em;
	right: 1.3em;

	@media screen and (min-width: 1280px) {
		top: 1.4em;
		right: 1.7em;
	}
`;

const Title = styled.h2`
	margin: 0;
	font-size: 1.6em;
	color: var(--modal-title-text-color);
	font-weight: normal;

	@media screen and (min-width: 1280px) {
		font-size: 2.3em;
	}
`;

const Description = styled.p`
	color: var(--modal-text-color);
	padding: 0;
	line-height: 1.55;
	margin: 1.6em 0 0;
	font-size: 0.9em;

	@media screen and (min-width: 1280px) {
		line-height: 1.65;
		font-size: 1em;
	}
`;

const Form = styled.form`
	margin-top: 2.3em;
	text-align: right;
	padding-bottom: 1em;
`;

const Input = styled.input`
	color: var(--modal-input-text-color);
	border: 1px solid var(--modal-input-border-color);
	width: calc(100% - 1em - 2px);
	display: block;
	margin-bottom: 1em;
	padding: 0.5em;
	font-size: 1.3em;
	font-family: 'Lato';

	::placeholder {
		color: var(--modal-input-placeholder-color);
	}

	:focus {
		outline: 1px solid var(--modal-input-border-color);
	}

	@media screen and (min-width: 1280px) {
		font-size: 1.4em;
	}
`;

const Message = styled.textarea`
	color: var(--modal-input-text-color);
	border: 1px solid var(--modal-input-border-color);
	width: calc(100% - 1em - 2px);
	display: block;
	padding: 0.5em;
	font-size: 1.3em;
	font-family: 'Lato';
	resize: none;
	/* stylelint-disable */
	${(props) => !props.touched ? `
	:-moz-ui-invalid {
		box-shadow: none;
	}` : ''}
	/* stylelint-enable */

	::placeholder {
		color: var(--modal-input-placeholder-color);
	}

	:focus {
		outline: 1px solid var(--modal-input-border-color);
	}

	@media screen and (min-width: 1280px) {
		font-size: 1.4em;
	}
`;

const Submit = styled.button`
	cursor: pointer;
	background-color: var(--schedule-bg-color);
	color: var(--schedule-text-color);
	padding: 0.6em 1.5em;
	text-align: center;
	font-size: 1.3em;
	border-radius: 3px;
	border: 0;
	margin: 1.3em 0;

	:focus {
		border-radius: 0;
		outline: 1px solid var(--schedule-bg-color);
	}

	@media screen and (min-width: 1280px) {
		margin-top: 0.8em;
	}

	:disabled {
		cursor: auto;
		background-color: rgb(0, 173, 168, 0.5);
	}
`;

const Loader = styled.div`
	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}

	display: inline-block;
	width: 0.5em;
	height: 0.5em;
	vertical-align: middle;
	border: 3px solid var(--loader-ring-color);
	border-radius: 50%;
	border-top-color: var(--loader-bar-color);
	animation: spin 1s ease-in-out infinite;
`;

const STATES = {
	pending: 1,
	sending: 2,
	submitted: 3,
	failed: 4
};

const STATE_COPY = {
	1: {
		title: 'Schedule Meeting',
		description: `Thank you for your interest in Libretto.
		If you would like to learn more about our platform and solutions, please complete this form.`
	},
	2: {
		title: 'Schedule Meeting',
		description: `Thank you for your interest in Libretto.
		If you would like to learn more about our platform and solutions, please complete this form.`
	},
	3: {
		title: 'Thank You',
		description: 'Your information has been successfully submitted.'
	},
	4: {
		title: 'Error',
		description: `We were unable to complete your request.
		Please email us at contact@libretto.io.`
	}
};

class ScheduleForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			name: null,
			organization: null,
			title: null,
			email: null,
			message: null,
			formState: STATES.pending,
			isTouched: false
		};

		this.el = document.createElement('div');

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSubmitClick = this.handleSubmitClick.bind(this);
		this.handleUserInput = this.handleUserInput.bind(this);
	}

	handleSubmitClick() {
		this.setState({
			isTouched: true
		});
	}

	handleUserInput(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	async handleSubmit(e) {
		this.setState({
			formState: STATES.sending
		});

		e.preventDefault();
		try {
			let token = jwt.sign({contactForm: true}, SEC, {expiresIn: 120});
			let {name, organization, title, email, message} = this.state;
			let response = await fetch('https://api.libretto.io/v1/schedule/', {
				method: 'POST',
				body: JSON.stringify({
					name,
					organization,
					title,
					email,
					message
				}),
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			});

			this.setState({
				formState: response.ok ? STATES.submitted : STATES.failed
			});
		} catch (error) {
			this.setState({
				formState: STATES.failed
			});
		}
	}

	render() {
		return <Wrapper>
			<IconElement onClick={this.props.onClose}>
				<img
					src={closeIcon}
					alt="Close"
					title="Close"
				/>
			</IconElement>
			<Title key={new Date()}>
				{STATE_COPY[this.state.formState].title}
			</Title>
			<Description>
				{STATE_COPY[this.state.formState].description}
			</Description>
			{
				this.state.formState === STATES.pending || this.state.formState === STATES.sending ?
					<Form onSubmit={this.handleSubmit}>
						<Input
							required
							onChange={this.handleUserInput}
							type="text"
							name="name"
							autoComplete="name"
							placeholder="Name (required)"
						/>
						<Input
							required
							onChange={this.handleUserInput}
							type="text"
							name="organization"
							autoComplete="organization"
							placeholder="Firm name (required)"
						/>
						<Input
							required
							onChange={this.handleUserInput}
							type="text"
							name="title"
							placeholder="Title (required)"
						/>
						<Input
							required
							onChange={this.handleUserInput}
							type="email"
							name="email"
							autoComplete="email"
							placeholder="Email (required)"
						/>
						<Message
							required
							touched={this.state.isTouched}
							maxLength={500}
							minLength={10}
							onChange={this.handleUserInput}
							name="message"
							placeholder="Message (required)"
						/>
						<Submit
							onClick={this.handleSubmitClick}
							disabled={this.state.formState === STATES.sending}>
							Submit{' '}
							{
								this.state.formState === STATES.sending ? <Loader /> : '\u00bb'
							}
						</Submit>
					</Form> :
					<Submit onClick={this.props.onClose}>
						Close &raquo;
					</Submit>
			}
		</Wrapper>;
	}
}

ScheduleForm.propTypes = {
	onClose: PropTypes.func.isRequired
};

export default ScheduleForm;
