import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import logo from '../assets/logos/libretto-logo-on-dark.svg';
import ModalTrigger from './ModalTrigger';

const Container = styled.div`
	background: var(--footer-bg-color);
`;

const Wrapper = styled.footer`
	background: var(--footer-bg-color);
	padding: 5em;
	max-width: 1280px;
	margin: 0 auto;

	@media screen and (min-width: 960px) {
		display: grid;
		grid-template-columns: repeat(2, [col] 50%);
	}
`;

const Right = styled.div`
	@media screen and (max-width: 959px) {
		margin-top: 7em;
	}

	@media screen and (min-width: 960px) {
		padding: 1.4em 0 0 8em;
	}

	@media screen and (min-width: 1280px) {
		padding: 1em 0 0 16.5em;
	}
`;

const Img = styled.img`
	max-width: 9em;
`;

const Nav = styled.nav`
	@media screen and (min-width: 960px) and (max-width: 1279px) {
		margin-top: 1.4em;
	}
`;

const Address = styled.address`
	font-style: normal;
	color: var(--footer-text-color);
	line-height: 1.8em;
	margin-bottom: 1.2em;

	@media screen and (min-width: 1280px) {
		font-size: 1.1em;
		line-height: 1.5em;
	}
`;

const Copyright = styled.div`
	color: var(--footer-copyright-color);
	text-align: center;
	margin: 7.2em 0 0;
	font-size: 0.9em;

	@media screen and (min-width: 960px) {
		grid-column: 1 / span 2;
		margin-top: 6.2em;
	}

	@media screen and (min-width: 960px) {
		margin-top: 9.2em;
	}

	img {
		max-height: 1em;
	}
`;

const Item = styled(Link)`
	text-transform: uppercase;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	color: var(--navigation-text-color);
	display: table;
	margin: 0.9em 0 0;

	&:hover {
		text-decoration: underline;
	}

	@media screen and (min-width: 960px) {
		margin-left: 7em;
	}

	@media screen and (min-width: 1280px) {
		margin-left: 12em;
	}
`;

const ScheduleItem = styled(ModalTrigger)`
	text-transform: uppercase;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	color: var(--navigation-text-color);
	display: table;
	margin: 0.9em 0 0;

	&:hover {
		text-decoration: underline;
	}

	@media screen and (min-width: 960px) {
		margin-left: 7em;
	}

	@media screen and (min-width: 1280px) {
		margin-left: 12em;
	}
`;

const Footer = () =>
	<Container>
		<Wrapper>
			<div>
				<Nav>
					<Item
						to="/#our-approach-section">
						Our Approach
					</Item>
					<Item
						to="/#our-solution-section">
						Our Solution
					</Item>
					<ScheduleItem
						href="/#schedule-meeting-section">
						Schedule Meeting
					</ScheduleItem>
					<Item
						to="/privacy-policy/">
						Privacy Policy
					</Item>
					<Item
						to="/legal-notice/">
						Legal Notice
					</Item>
				</Nav>
			</div>
			<Right>
				<Img
					alt="Libretto Logo in Black and White"
					src={logo} />
				<Address>
					6060 Center Drive, 10th Floor<br />
					Los Angeles, CA 90045
				</Address>
			</Right>
			<Copyright>
				&copy; {new Date().getFullYear()} Libretto{' '}
				<img
					src="/libretto-logo.png"
					alt="Libretto Logo"
					title="Libretto Logo" />
			</Copyright>
		</Wrapper>
	</Container>;

export default Footer;
