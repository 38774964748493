import {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class Modal extends PureComponent {
	constructor(props) {
		super(props);
		this.el = document.createElement('div');
		this.el.id = 'modal-wrapper';
	}

	componentDidMount() {
		document.body.className = 'modal';
		document.getElementById('modal-root').appendChild(this.el);
	}

	componentWillUnmount() {
		document.body.className = '';
		document.getElementById('modal-root').removeChild(this.el);
	}

	render() {
		return ReactDOM.createPortal(
			this.props.children,
			this.el,
		);
	}
}

Modal.propTypes = {
	children: PropTypes.node.isRequired
};

export default Modal;
