import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SiteNav from './SiteNav';
import heroImageMobile from '../assets/images/jetty-mobile.jpg';

const Wrapper = styled.header`
	max-width: 1280px;
	margin: 0 auto;

	@media screen and (min-width: 960px) {
		display: grid;
		grid-template-columns: repeat(2, [col] 50%);
	}

	&.gp {
		@media screen and (min-width: 960px) {
			nav {
				background: transparent;
			}

			nav a {
				color: var(--navigation-alt-text-color);

				&:hover {
					border-bottom-color: var(--navigation-alt-text-color);
				}
			}
		}

		.nav-wrapper {
			background: none;
		}
	}

	&.hp {
		background-color: var(--header-bg-color);

		@media screen and (max-width: 959px) {
			background-image: url(${heroImageMobile});
			background-position: 0 68px;
			background-repeat: no-repeat;
			background-size: contain;
			height: 37em;
		}
	}
`;

const Left = styled.div`
	@media screen and (min-width: 400px) and (max-width: 959px) {
		background-color: var(--header-bg-color);
		height: 18em;
	}

	@media screen and (min-width: 960px) {
		grid-column: 1;
		height: 40.5em;
	}

	@media screen and (min-width: 1280px) {
		height: 55.6em;
	}
`;

const Text = styled.p`
	font-size: 1.7em;
	max-width: 12.2em;
	margin: 10.5em auto 0;
	text-align: center;
	line-height: 1.32em;
	font-weight: lighter;
	color: var(--header-text-color);

	@media screen and (min-width: 400px) and (max-width: 959px) {
		margin: 9.5em auto 0;
		padding-top: 1em;
	}

	@media screen and (min-width: 960px) {
		font-size: 1.8em;
		max-width: 13em;
		margin-top: 7.6em;
		text-align: left;
	}

	@media screen and (min-width: 1280px) {
		font-size: 2.2em;
		line-height: 1.3em;
		max-width: 14em;
		margin-top: 8.5em;
	}
`;

const Header = ({generic}) =>
	<Wrapper className={generic ? 'gp' : 'hp'}>
		<SiteNav />
		{
			!generic ?
				<Left>
					<Text>
						Libretto delivers a powerful advice platform built to manage wealth ranging from
						the least to most complex.
					</Text>
				</Left> :
				null
		}
	</Wrapper>;

Header.propTypes = {
	generic: PropTypes.bool
};

Header.defaultProps = {
	generic: false
};

export default Header;
