import styled from 'styled-components';

export const Container = styled.main`
	max-width: 700px;
	line-height: 1.5;
	margin: 4em auto;
	padding: 0 1em;
	min-height: 35em;
`;

export const SectionContainer = styled.div`
	&.primary {
		background-color: var(--main-bg-color);
	}

	&.secondary {
		background-color: var(--secondary-bg-color);
	}

	&.split {
		background: linear-gradient(to right, var(--tertiary-bg-color) 50%, var(--secondary-bg-color));
	}
`;
